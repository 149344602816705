

















































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { POLLUTANTS, POLLUTANTS_QAI, POLLUTANTS_QAE } from "@/pollutants";

@Component({})
export default class PollutantBarMobile extends Vue {
  @Prop({
    default: () => {
      return {
        co2: 0,
        co: 0,
        cov: 0,
        pm1: 0,
        pm25: 0,
        pm10: 0,
        temperature: 0,
        humidity: 0,
        no2: 0,
        qai: 0,
      };
    },
  })
  public pollutants: any;
  @Prop({ default: null }) public qae: any;
  @Prop({ default: null }) public alerts: any;
  @Prop({ default: null }) public thresholds: any;
  @Prop() public offlineText: any;
  @Prop({ default: true }) public online: any;
  @Prop({
    default: () => {
      return {
        tips: [],
      };
    },
  })
  public tips: any;
  @Prop({ default: false }) public areaMode!: boolean;
  @Prop({ default: "qai" }) public mode: any;
  @Prop({ default: null }) public indicators: any;

  public expanded: boolean = true;
  public alertsIndicators: any = {};
  public tipImage1: any = null;
  public tip1: any = null;
  public tipImage2: any = null;
  public tip2: any = null;

  private indicatorsTemplate: any = {
    qai: POLLUTANTS_QAI,
    qae: POLLUTANTS_QAE,
  };

  public created() {
    this.updateIndicatorsColors();
    this.updateTips();
  }

  get indicatorsList() {
    if (!this.indicators) return [];
    return this.indicators.split(",");
  }

  get qualityIndice() {
    if (this.online === false) {
      return 3;
    }
    if (this.mode === "qai") {
      return this.pollutants.qai;
    } else if (this.mode === "qae") {
      return this.pollutants.qae;
    }
  }

  get computedData() {
    const entries: any[] = [];
    for (const indicator of this.indicatorsTemplate[this.mode]) {
      // filter indicator if passed
      if (this.indicators !== null && !this.indicatorsList.includes(indicator)) {
        continue;
      }
      const pollutant = POLLUTANTS[indicator];
      entries.push({
        field: pollutant.field,
        value: pollutant.formatValue(this.pollutants[pollutant.field]),
        title: pollutant.titleField,
        unit: pollutant.unit,
        cssClass: this.alertsIndicators[pollutant.field],
      });
    }
    return entries;
  }

  private showSituationFor(pollutant: string) {
    if (this.tips === null) {
      return;
    }
    for (const tip of this.tips.tips) {
      if (tip.pollutants.includes(pollutant)) {
        this.$emit("show-situations", tip);
      }
    }
  }

  @Watch("alerts")
  @Watch("thresholds")
  @Watch("pollutants")
  private updateIndicatorsColors() {
    if (this.alerts !== null) {
      this.updateAlertsIndicators();
    } else if (this.thresholds !== null) {
      this.updateAlertsIndicatorsFromThresholds();
    } else {
      this.alertsIndicators = {};
    }
  }

  private updateAlertsIndicators() {
    const indicators = {};
    for (const entry of this.alerts) {
      indicators[entry.indicator] = entry.is_critical ? "critical" : "warning";
    }
    this.alertsIndicators = indicators;
  }

  private updateAlertsIndicatorsFromThresholds() {
    const indicators = {};
    for (const [pollutant, thresholds] of Object.entries(this.thresholds)) {
      const value = this.pollutants[pollutant];
      const entry: any = thresholds;
      if (value < entry.warning) {
        continue;
      }
      indicators[pollutant] = value >= entry.critical ? "critical" : "warning";
    }
    this.alertsIndicators = indicators;
  }

  @Watch("tips")
  private updateTips() {
    let tipImage1 = null;
    let tipImage2 = null;
    let tip1 = null;
    let tip2 = null;
    for (const entry of this.tips.tips) {
      if (entry.icon === null) {
        continue;
      }
      if (tipImage1 === null) {
        tip1 = entry;
        tipImage1 = this.tips.icons[entry.icon];
      } else if (tipImage2 === null) {
        tip2 = entry;
        tipImage2 = this.tips.icons[entry.icon];
      }
    }

    this.tipImage1 = tipImage1;
    this.tipImage2 = tipImage2;
    this.tip1 = tip1;
    this.tip2 = tip2;
  }

  private goSwipe(evt: any) {
    if (evt.direction === "left") {
      this.$emit("swipe-next");
    } else {
      this.$emit("swipe-prev");
    }
  }
}
