import { Manager } from 'socket.io-client';
import config from './config';
import { Component, Vue } from 'vue-property-decorator';

let manager: any = null;

@Component
class SocketIOMixin extends Vue {
  public sockets: any = [];

  public ioListen(namespace: string) {
    if (manager === null) {
      const query = {};
      const s: string = localStorage.getItem('jwt') || '';
      if (localStorage.getItem('jwt')) {
        query['token'] = s;
      }
      manager = new Manager(config.socketIOUrl, {
        transports: ['websocket'],
        query,
      });
    }
    const socket = manager.socket(namespace);
    this.sockets.push(socket);
    return socket;
  }

  public ioNamespace(namespace: string) {
    for (const socket of this.sockets) {
      if (socket.nsp === namespace) {
        return socket;
      }
    }
  }

  public ioClose() {
    for (const socket of this.sockets) {
      socket.removeAllListeners();
      socket.close();
    }
    this.sockets = [];
  }

}

export {
  SocketIOMixin,
};
