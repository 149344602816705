















































































































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import date from 'quasar/src/utils/date.js';;
import CCToggle from '../components/CCToggle.vue';
import { AxiosResponse } from 'axios';
import axios from 'axios';
import { SocketIOMixin } from '../io-utils';
import PollutantBar from '../components/PollutantBar.vue';
import PollutantBarMobile from '../components/PollutantBarMobile.vue';

@Component({
  components: {
    CCToggle,
    PollutantBar,
    PollutantBarMobile,
  },
})
export default class AirQualityBuilding extends Mixins(SocketIOMixin) {
  @Prop() public id!: number;

  public $store: any;
  public building: any = null;
  public buildingLastData: any = {
    data: null,
    areas: null,
  };
  public areasQai: any = {};
  public buildingQai: number = 0;
  public viewType: string = 'cards';
  public areaFilter: string = '';
  public areas: any = [];
  public areasWithAlerts: boolean = false;
  public areasListWithAlerts: any = [];
  public areasOnline: any = {};
  public toggleAllData: boolean = false;
  public loading: boolean = false;
  private needRefresh: boolean = false;

  public created() {
    this.viewType = localStorage.getItem('dbAreaViewType') || 'cards';
    this.areasWithAlerts = localStorage.getItem('dbAreasWithAlerts') === 'on';
    this.loading = true;
    this.refresh();
  }

  public refresh() {
    this.building = this.$store.getters.getBuildingFromId(this.id);
    this.loadBuilding();
  }

  public mounted() {
    this.ioListen('/qai-buildings').on('indicators', (message: any) => {
      const buildingId: any = message['building_id'];
      if (this.id !== buildingId) { return; }
      const data: any = message['data'];
      Vue.set(this.buildingLastData.data, 'data', data);
    });
    if (this.$can('read', 'data-area')) {
      this.ioListen('/qai-building-areas').on('qai', (message: any) => {
        const buildingId: any = message['building_id'];
        if (this.id !== buildingId) { return; }
        const data: any = message['data'];
        this.buildingLastData.areas = data;
        this.updateAreasQai();
      });
    }
  }

  public destroyed() {
    this.ioClose();
  }

  @Watch('id')
  private onParamsChanged() {
    this.needRefresh = true;
    this.$nextTick().then(() => {
      if (this.needRefresh) {
        this.loading = true;
        this.refresh();
        this.needRefresh = false;
      }
    });
  }

  @Watch('areasWithAlerts')
  private saveAreasWithAlerts() {
    localStorage.setItem('dbAreasWithAlerts', this.areasWithAlerts ? 'on' : 'off');
  }

  private loadBuilding() {
    this.$store.dispatch('ensureBuilding', {
      buildingId: this.id,
      params: { recursive: true },
    })
    .then((response: any) => {
      this.building = response;
    })
    .catch((error: any) => {
      this.globalError(error);
    });

    axios.get(`/building/${this.id}/data/last`)
    .then((response: AxiosResponse) => {
      this.buildingLastData = response.data;
      if (this.buildingLastData.data === null) {
        this.buildingQai = 3;
        this.buildingLastData.data = {
          data: {
            qai: 3,
          },
          info: {
            thresholds: {},
          },
        };
      } else {
        this.buildingQai = this.buildingLastData.data.qai;
      }

      if (this.buildingLastData.areas !== undefined) {
        this.updateAreasQai();
      }

      this.loading = false;
    })
    .catch((error: any) => {
      this.globalError(error);
    });

  }

  private updateAreasQai() {
    const areasListWithAlerts: any[] = [];
    const areasOnline = {};
    const areasQai = {};

    for (const entry of Object.entries(this.buildingLastData.areas)) {
      const areaId = parseInt(entry[0], 10);
      const value: any = entry[1];
      areasOnline[areaId] = value['online'];
      areasQai[areaId] = value['qai'];
      if (areasOnline[areaId] !== true) {
        areasQai[areaId] = -1;
      } else if (areasQai[areaId] > 0) {
        areasListWithAlerts.push(areaId);
      }
    }

    this.areasListWithAlerts = areasListWithAlerts;
    this.areasOnline = areasOnline;
    this.areasQai = areasQai;
  }

  private setViewType(vt: string) {
    this.viewType = vt;
    localStorage.setItem('dbAreaViewType', vt);
  }

  @Watch('building')
  private updateAreas() {
    if (this.building === undefined || this.building.levels === undefined) {
      return;
    }
    const areas: any[] = [];
    for (const level of this.building.levels) {
      for (const area of level.areas) {
        areas.push(area);
      }
    }
    this.areas = areas;
  }

  get areasList() {
    let areas = this.areas;
    if (this.areaFilter) {
      const pattern: string = this.areaFilter.toLowerCase();
      areas = areas.filter((area: any) => {
        return area.name.toLowerCase().indexOf(pattern) >= 0;
      });
    }
    if (this.areasWithAlerts) {
      areas = areas.filter((area: any) => this.areasListWithAlerts.includes(area.id));
    }
    return areas.sort((a: any, b: any) => {
      const onA: boolean = this.isAreaOffline(a);
      const onB: boolean = this.isAreaOffline(b);
      if (onA === onB) {
        return a.name.localeCompare(b.name);
      } else {
        if (onA) { return 1; }
        return -1;
      }
    });
  }

  get cardCss() {
    if (this.$q.screen.lt.sm && this.$q.screen.width > 300) {
     return 'col-6';
    }
    if (this.$q.screen.width < 800) {
      return 'col-12';
    } else if (this.$q.screen.md) {
      return 'col-4';
    } else if (this.$q.screen.lg) {
      return 'col-3';
    } else if (this.$q.screen.xl) {
      return 'col-2';
    }
    return 'col-6';
  }

  private isAreaOffline(area: any) {
    if (area === undefined) {
      return true;
    }
    return this.areasOnline[area.id] !== true;
  }

  private getBuildingIds() {
    return this.$store.state.buildings.map((building: any) => building.id);
  }

  private modFix(v: number, n: number) {
    return ((v % n) + n) % n;
  }

  private goBuildingNext(amount: number) {
    const buildingIds = this.getBuildingIds();
    const idx = buildingIds.indexOf(this.id);
    const idxNext = this.modFix((idx + amount), buildingIds.length);
    if (idx === idxNext) {
      return;
    }
    this.$router.push({
      name: 'airquality-building',
      params: {
        id: String(buildingIds[idxNext]),
      },
    });
  }

}
