import { i18n } from "@/i18n";

const POLLUTANTS_ORDER = [
  "co2",
  "temperature",
  "humidity",
  "cov",
  "no2",
  "no2o3",
  "ozone",
  "pm1",
  "pm25",
  "pm10",
  "pressure",
  "sound",
  "co",
];

const POLLUTANTS_QAI = ["co2", "cov", "pm25", "temperature", "humidity", "no2o3"];
const POLLUTANTS_QAE = ["cov", "pm25", "no2o3", "no2", "ozone", "temperature", "humidity", "pressure"];

const POLLUTANTS = {
  no2: {
    field: "no2",
    titleField: "FIELD_NO2_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "ppb",
    queryUnit: "ppb",
  },
  no2o3: {
    field: "no2o3",
    titleField: "FIELD_NO2O3_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "ppb",
    queryUnit: "ppb",
  },
  ozone: {
    field: "ozone",
    titleField: "FIELD_OZONE_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "ppb",
    queryUnit: "ppb",
  },
  co: {
    field: "co",
    titleField: "FIELD_CO_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "mg/m³",
    queryUnit: "mgm3",
  },
  co2: {
    field: "co2",
    titleField: "FIELD_CO2_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "ppm",
    queryUnit: "ppm",
  },
  cov: {
    field: "cov",
    titleField: "FIELD_COV_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "ppb",
    queryUnit: "ppb",
  },
  temperature: {
    field: "temperature",
    titleField: "FIELD_TEMPERATURE",
    formatValue: (value: number) => Math.round(value),
    unit: "°C",
    queryUnit: "celsius",
  },
  humidity: {
    field: "humidity",
    titleField: "FIELD_HUMIDITY",
    formatValue: (value: number) => Math.round(value),
    unit: "%",
    queryUnit: "percent",
  },
  pm1: {
    field: "pm1",
    titleField: "FIELD_PM1_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "µg/m³",
    queryUnit: "ugm3",
  },
  pm25: {
    field: "pm25",
    titleField: "FIELD_PM25_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "µg/m³",
    queryUnit: "ugm3",
  },
  pm10: {
    field: "pm10",
    titleField: "FIELD_PM10_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "µg/m³",
    queryUnit: "ugm3",
  },
  sound: {
    field: "sound",
    titleField: "FIELD_SOUND_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "db",
    queryUnit: "db",
  },
  pressure: {
    field: "pressure",
    titleField: "FIELD_PRESSURE_SHORT",
    formatValue: (value: number) => Math.round(value),
    unit: "hPa",
    queryUnit: "hpa",
  },
};

const POLLUTANTS_FILTER_OPTIONS = Object.values(POLLUTANTS)
  .map((pollutant: any) => ({
    value: pollutant.field,
    label: i18n.t(pollutant.titleField),
    unit: {
      value: pollutant.queryUnit,
      label: pollutant.unit,
    },
  }))
  .sort((a: any, b: any) => POLLUTANTS_ORDER.indexOf(a.value) - POLLUTANTS_ORDER.indexOf(b.value));

function getPollutantsOptionsForAnalyzer(analyzer: any) {
  if (!analyzer) {
    return POLLUTANTS_FILTER_OPTIONS;
  }
  const indicatorsList = analyzer.indicators.split(",");
  return POLLUTANTS_FILTER_OPTIONS.filter((option: any) => indicatorsList.includes(option.value));
}

function getPollutantsOptionsForArea(area: any) {
  let indicatorsList: Array<string> = [];
  if (area.indicators) {
    indicatorsList = area.indicators.split(",");
  } else {
    indicatorsList = ["co2", "temperature", "humidity", "cov", "no2o3", "pm1", "pm25", "pm10"];
  }
  return POLLUTANTS_FILTER_OPTIONS.filter((option: any) => indicatorsList.includes(option.value));
}

export {
  POLLUTANTS,
  POLLUTANTS_QAI,
  POLLUTANTS_QAE,
  POLLUTANTS_FILTER_OPTIONS,
  getPollutantsOptionsForAnalyzer,
  getPollutantsOptionsForArea,
};
