











import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class CCToggle extends Vue {
    @Prop() public value!: any;
    @Prop() public label!: any;
    @Prop() public color!: any;
    @Prop() public noborder!: boolean;
    @Prop() public thinlabel!: boolean;
    @Prop() public fullwidth!: boolean;

    private updateInput(value: boolean) {
        this.$emit('input', value);
    }
}
