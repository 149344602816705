























































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { POLLUTANTS, POLLUTANTS_QAI, POLLUTANTS_QAE } from "@/pollutants";

@Component({})
export default class PollutantBar extends Vue {
  @Prop({
    default: () => {
      return {
        co2: 0,
        co: 0,
        cov: 0,
        pm1: 0,
        pm25: 0,
        pm10: 0,
        ozone: 0,
        no2o3: 0,
        pressure: 0,
        temperature: 0,
        humidity: 0,
        no2: 0,
        qai: 0,
      };
    },
  })
  public pollutants: any;
  @Prop({ default: null }) public qae: any;
  @Prop({ default: null }) public alerts: any;
  @Prop({ default: null }) public thresholds: any;
  @Prop() public offlineText: any;
  @Prop({ default: true }) public online: any;
  @Prop({ default: "qai" }) public mode: any;
  @Prop({ default: null }) public indicators: any;

  public expanded: boolean = true;
  public alertsIndicators: any = {};

  private indicatorsTemplate: any = {
    qai: POLLUTANTS_QAI,
    qae: POLLUTANTS_QAE,
  };

  public created() {
    this.expanded = localStorage.getItem("dbPollutantBarExpanded") === "true";
    this.updateIndicatorsColors();
  }

  get indicatorsList() {
    if (!this.indicators) return [];
    return this.indicators.split(",");
  }

  get qualityIndice() {
    if (this.online === false) {
      return 3;
    }
    if (this.mode === "qai") {
      return this.pollutants.qai;
    } else if (this.mode === "qae") {
      return this.pollutants.qae;
    }
  }

  get computedData() {
    const entries: any[] = [];
    // let value;
    for (const indicator of this.indicatorsTemplate[this.mode]) {
      // filter indicator if passed
      if (this.indicators !== null && !this.indicatorsList.includes(indicator)) {
        continue;
      }

      const pollutant = POLLUTANTS[indicator];
      entries.push({
        field: pollutant.field,
        value: pollutant.formatValue(this.pollutants[pollutant.field]),
        title: pollutant.titleField,
        unit: pollutant.unit,
        cssClass: this.alertsIndicators[pollutant.field],
      });
    }
    if (entries.length < 4) {
      this.expanded = false;
    }
    return entries;
  }

  @Watch("expanded")
  private savePollutantBarExpanded() {
    localStorage.setItem("dbPollutantBarExpanded", this.expanded ? "true" : "false");
  }

  @Watch("alerts")
  @Watch("thresholds")
  @Watch("pollutants")
  private updateIndicatorsColors() {
    if (this.alerts !== null) {
      this.updateAlertsIndicators();
    } else if (this.thresholds !== null) {
      this.updateAlertsIndicatorsFromThresholds();
    } else {
      this.alertsIndicators = {};
    }
  }

  private updateAlertsIndicators() {
    const indicators = {};
    for (const entry of this.alerts) {
      indicators[entry.indicator] = entry.is_critical ? "critical" : "warning";
    }
    this.alertsIndicators = indicators;
  }

  private updateAlertsIndicatorsFromThresholds() {
    const indicators = {};
    for (const [pollutant, thresholds] of Object.entries(this.thresholds)) {
      const value = this.pollutants[pollutant];
      const entry: any = thresholds;
      if (value < entry.warning) {
        continue;
      }
      indicators[pollutant] = value >= entry.critical ? "critical" : "warning";
    }
    this.alertsIndicators = indicators;
  }
}
